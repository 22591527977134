import { defineStore } from 'pinia'

export const useSearchInvoiceStore = defineStore('SearchInvoiceStore', {
  state: () => {
    return {
      invoiceStatus: 'TEMP' as string,
      periodFrom: null as string | null,
      periodUntil: null as string | null,
      invoiceDebtorType: null as string | null,
      ident: null as number | null,
      invoiceRunId: null as number | null,
      invoiceTypeIds: [] as number[],
      smpEncashmentEntityId: null as number | null,
      debtorNo: null as string | null,
      approved: null as string | null,
      amountMin: null as number | null,
      amountMax: null as number | null,
      sendingChannel: null as string | null
    }
  }
})
