<template>
  <div>
    <h2 v-html="$t('search_invoice_card_title')"></h2>
    <!--    Status Faktura -->
    <v-select
      id="search_invoice_card_invoice_status"
      v-model="invoiceStore.invoiceStatus"
      :label="$t('search_invoice_card_invoice_status')"
      :items="invoiceStatuses"
      :item-title="`name${$getUpLang()}`"
      item-value="name"
      clearable
      @keyup.enter="emitSearch"
    />
    <!--    Hat MPD1/Leistungs Periode von -->
    <DbmMonthPicker v-model="invoiceStore.periodFrom" :dateLabel="$t('search_invoice_card_mpd1_period_from')" :clearable="true" />
    <!--    bis -->
    <DbmMonthPicker v-model="invoiceStore.periodUntil" :dateLabel="$t('search_invoice_card_mpd1_period_until')" :clearable="true" returnType="lastOfMonth" />
    <!--    Debitor (Kategorie) -->
    <v-select
      id="search_invoice_card_debtor"
      v-model="invoiceStore.invoiceDebtorType"
      :label="$t('search_invoice_card_debtor')"
      :items="invoiceDebtorTypes"
      :item-title="`name${$getUpLang()}`"
      item-value="name"
      clearable
      @keyup.enter="emitSearch"
    />
    <!-- RE-Nr. (ident) -->
    <v-text-field
      id="search_invoice_card_invoice_no"
      v-model="invoiceStore.ident"
      :label="$t('search_invoice_card_invoice_no')"
      type="text"
      @keyup.enter="emitSearch"
    />
    <!--    Rechnungslauf Nr. -->
    <v-text-field
      id="search_invoice_card_invoice_run_no"
      v-model="invoiceStore.invoiceRunId"
      :label="$t('search_invoice_card_invoice_run_no')"
      type="text"
      @keyup.enter="emitSearch"
    />
    <!--    Faktura Typ -->
    <v-select
      id="search_invoice_card_invoice_type"
      v-model="invoiceStore.invoiceTypeIds"
      :label="$t('search_invoice_card_invoice_type')"
      :items="invoiceTypes"
      :item-title="`name${$getUpLang()}`"
      item-value="id"
      clearable
      @keyup.enter="emitSearch"
      multiple
    />

    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!--    Verband (=Inkassostellen) -->
      <v-select
        id="search_invoice_card_association"
        v-model="invoiceStore.smpEncashmentEntityId"
        :label="$t('search_invoice_card_association')"
        :items="smpEncashmentEntities"
        :item-title="entityName"
        item-value="id"
        clearable
        @keyup.enter="emitSearch"
        v-if="$privileges.has({ path: '/smpEncashmentEntities', permission: 'allDomains' })"
      />
      <!--      Debitoren-Nr--->
      <v-text-field
        id="search_invoice_card_debtor_no"
        v-model="invoiceStore.debtorNo"
        :label="$t('search_invoice_card_debtor_no')"
        type="text"
        @keyup.enter="emitSearch"
      />
      <!--    Abrechnungsdatum (approved)-->
      <DbmDatePicker v-model="invoiceStore.approved" :dateLabel="$t('search_invoice_card_date')" :clearable="true" />
      <!--     Betrag von --->
      <v-text-field
        id="search_invoice_card_invoice_amount_from"
        v-model="invoiceStore.amountMin"
        :label="$t('search_invoice_card_invoice_amount_from')"
        type="text"
        @keyup.enter="emitSearch"
      />
      <!--     Betrag bis --->
      <v-text-field
        id="search_invoice_card_invoice_amount_to"
        v-model="invoiceStore.amountMax"
        :label="$t('search_invoice_card_invoice_amount_to')"
        type="text"
        @keyup.enter="emitSearch"
      />
      <!--      Rechnung per (channel)-->
      <v-select
        id="search_invoice_card_invoice_per"
        v-model="invoiceStore.sendingChannel"
        :label="$t('search_invoice_card_invoice_per')"
        :items="sendingChannelOptions"
        :item-title="`name${$getUpLang()}`"
        item-value="value"
        clearable
        @keyup.enter="emitSearch"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import { useInvoiceStatusStore } from '@/store/enums/InvoiceStatusStore'
import { useInvoiceDebtorTypesStore } from '@/store/enums/InvoiceDebtorTypesStore'
import { useInvoiceTypesStore } from '@/store/enums/InvoiceTypesStore'
import { useSmpEncashmentEntityStore } from '@/store/enums/SmpEncashmentEntityStore'
import { useSearchInvoiceStore } from '@/store/SearchInvoiceStore'
import DbmDatePicker from '@/components/dbmDatePicker.vue'

/////  search card

const emit = defineEmits(['search'])

const emitSearch = () => {
  emit('search')
}

const expanded = ref(false)

/////  invoice store

const invoiceStore = useSearchInvoiceStore()

/////  enums

const invoiceStatusStore = useInvoiceStatusStore()
const invoiceDebtorTypesStore = useInvoiceDebtorTypesStore()
const invoiceTypesStore = useInvoiceTypesStore()
const encashmentEntitiesStore = useSmpEncashmentEntityStore()

const sendingChannelOptions = computed(() => {
  return [
    { id: 1, value: 'SMS', nameDe: 'SMS', nameFr: 'SMS', nameIt: 'SMS' },
    { id: 2, value: 'EMAIL', nameDe: 'Mail', nameFr: 'Courriel', nameIt: 'EMail' },
    { id: 3, value: 'POST', nameDe: 'Post', nameFr: 'Post', nameIt: 'Posta' },
    { id: 4, value: 'INTERNAL', nameDe: 'Papier', nameFr: 'Papier', nameIt: 'Carta' }
  ]
})

const invoiceStatuses = computed(() => {
  return invoiceStatusStore.invoiceStatus
})

const invoiceDebtorTypes = computed(() => {
  return invoiceDebtorTypesStore.invoiceDebtorTypes
})

const invoiceTypes = computed(() => {
  return invoiceTypesStore.invoiceTypes
})

interface SmpEnchashmentEntity {
  ident: number
  name: string
  displayName?: string
}

const smpEncashmentEntities = computed<SmpEnchashmentEntity[]>(() => {
  return encashmentEntitiesStore.smpEncashmentEntities.map((org: SmpEnchashmentEntity) => ({
    ...org,
    displayName: `${org.ident} ${org.name} `
  }))
})

const entityName = (item: SmpEnchashmentEntity) => {
  return item.displayName || item.name
}

onBeforeMount(() => {
  invoiceStatusStore.fetchInvoiceStatus()
  invoiceDebtorTypesStore.fetchInvoiceDebtorTypes()
  invoiceTypesStore.fetchInvoiceTypes()
  encashmentEntitiesStore.fetchSmpEncashmentEntities()
})
</script>
